import React, { useState, useEffect } from "react";

import { AiOutlineEye } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

import DefaultImg from "../../lib/images/d4.gif";

const ThumbNailSilders = ({ item }) => {
  const { images, title, price } = item.data;

  const [placeholderImg, setPlaceholderImg] = useState(DefaultImg);
  useEffect(() => {
    if (images && images.length > 0) {
      setPlaceholderImg(images[0]);
    } else {
      setPlaceholderImg(DefaultImg);
    }
  }, [images]);

  const navigate = useNavigate();

  return (
    <div key={item.id} className="col l3 m4 s6">
      <div
        onClick={() => {
          navigate(`/item/${item.id}`);
        }}
        className="card z-depth-0 levbitz_card"
        style={{
          borderRadius: "15px",
        }}
      >
        <div className="card-image">
          <img
            alt={title}
            src={placeholderImg}
            className="responsive-img"
            style={{
              borderRadius: 10,
              height: 180,
              objectFit: "cover",
            }}
          />

          <span
            style={{
              fontSize: ".7rem",
              margin: "0",
              padding: "0 4px",
              borderTopRightRadius: "5px",
              background: "#094c59",
            }}
            className="card-title"
          >
            {item.data.promotionType}
          </span>
          <small
            style={{
              width: "30px",
              height: "30px",
              textAlign: "center",
              zIndex: "0",
              background: "#094c59",
            }}
            className="btn-floating halfway-fab  z-depth-0"
          >
            <AiOutlineEye size={18} />
          </small>
        </div>
        <div
          style={{
            padding: "18px 10px",
          }}
          className="card-content"
        >
          <p className="truncate">{title}</p>
          <small
            className="truncate"
            style={{
              margin: 0,
              padding: 0,
              fontWeight: "600",
            }}
          >
          TSh: {price}
          </small>
        </div>
      </div>
    </div>
  );
};

export default ThumbNailSilders;
