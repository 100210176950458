import React from "react";
import MyGif from "../../lib/images/logo.png";
// import { Helmet } from "react-helmet-async";
// <Helmet prioritizeSeoTags>
//   <title>bantumart free online Classified ad Platform</title>
// </Helmet>;

function Loading() {
  return (
    <React.Fragment>
      <div
        style={{
          background: "#094c59",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
          flexDirection: "column",
        }}
      >
        <h4
          style={{
            color: "#fff",
            margin: 0,
            padding: 0,
          }}
        >
          bantuMart Classified welcomes you
        </h4>
        <p children="center">
          <img width={80} src={MyGif} alt="loader" />
        </p>
        <h6
          style={{
            margin: 0,
            padding: 0,
          }}
          className="white-text"
        >
          loading.....
        </h6>
      </div>
    </React.Fragment>
  );
}

export default Loading;
