import React, { lazy, useEffect, useState } from "react";
import "./App.css";
import Footer from "./components/Footer/Footer";

import { BrowserRouter, Routes, Route } from "react-router-dom";

import RegisterPage from "./Pages/RegisterPage/RegisterPage";
import PostAd from "./Pages/PostAdd/PostAdd";
import AuthProvider from "./lib/context/AuthContext/auth";
import PhotoUpload from "./Pages/PhotoUpload/PhotoUpload";
import Promote from "./Pages/Promote/Promote";
import Profile from "./Pages/Profile/Profile";
import PostedAddDetails from "./Pages/PostedAddDetails/PostedAddDetails";
import Products from "./components/Products/Products";
import MegaSearch from "./Pages/MegaSearch/MegaSearch";
import PrivateRoute from "./lib/PrivateRoute/PrivateRoute";
import SearchPage from "./Pages/SearchPage/SearchPage";
import ScrollToTop from "./lib/ScrollTop/ScrollTop";
import TermsPage from "./Pages/OtherPages/TermsPage/TermsPage";
import PrivacyPolicy from "./Pages/OtherPages/PrivacyPolicy/PrivacyPolicy";

import FixedBottom from "./components/FixedBottom/FixedBottom";

import ResetPassword from "./Pages/ResetPassword/ResetPassword";
import BottomCategories from "./Pages/BottomCategories/BottomCategories";
import AboutPage from "./Pages/AboutPage/AboutPage";
import WalkThrough from "./Business/BusinessPages/WalkThrough/WalkThrough";
import PricingPage from "./Business/BusinessPages/PricingPage/Pricing";
import Careers from "./Business/BusinessPages/Careers.js/Careers";
import SingleJob from "./Business/BusinessPages/SingleJob/SingleJob";
import JobApplication from "./Business/BusinessPages/JobApplication/JobApplication";
import NotFound from "./Business/BusinessPages/NotFound/NotFound";
import SiteMap from "./Business/BusinessPages/SiteMap/SiteMap";
import Team from "./Business/BusinessPages/Team/Team";
import Categories from "./Pages/Categories/Categories";
import DistrictSearch from "./Pages/DistrictSearch/DistrictSearch";
import ReportAd from "./Pages/ReportAd/ReportAd";
import AlertModal from "./components/AlertModal/AlertModal";
import MySelect from "./Playables/MySelect/MySelect";

const LoginPage = lazy(() => import("./Pages/LoginPage/LoginPage"));
const EditAdInfo = lazy(() => import("./EditAdInfo/EditAdInfo"));
const FaqsPage = lazy(() => import("./Business/FaqsPage/FaqsPage"));

const HomePage = lazy(() => import("./Pages/HomePage/HomePage"));

function App() {
  // const [width, setWidth] = useState(window.innerWidth);

  // const isAndroid = navigator.userAgent.toLowerCase().includes("android");
  // //const isiPhone = navigator.userAgent.toLowerCase().includes("iphone");

  // const handleResize = () => {
  //   setWidth(window.innerWidth);
  //   console.log(`Screen width: ${width}px`);
  // };
  // useEffect(() => {
  //   window.addEventListener("resize", handleResize);

  //   if (isAndroid && width < 620) {
  //     // alert("you on mobile");
  //     window.location.href =
  //       "https://play.google.com/store/apps/details?id=com.bantumart.uganda&pli=1";
  //   }

  //   return () => {
  //     window.removeEventListener("resize", handleResize);
  //   };
  // }, [width]);

  {
    /*console.log(width);
  {
    isAndroid && <p>This is an Android device.</p>;
  }
  {
    isiPhone && <p>This is an iPhone device.</p>;
  }*/
  }
  return (
    <>
      <BrowserRouter>
       {/* <AlertModal />*/}
        <ScrollToTop />

        <Routes>
          <Route path="/post_ad" element={<PrivateRoute />}>
            <Route path="/post_ad" element={<PostAd />} />
          </Route>
          <Route path="/" element={<HomePage />} />
          <Route path="/myselect" element={<MySelect />} />
          <Route path="/sign_in" element={<LoginPage />} />
          <Route path="/sign_up" element={<RegisterPage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/:categoryUrl" element={<Categories />} />
          <Route path="/walk-through" element={<WalkThrough />} />
          <Route path="/pricing" element={<PricingPage />} />
          <Route path="/single_job/:id" element={<SingleJob />} />
          <Route path="/apply/:id/:title" element={<PrivateRoute />}>
            <Route path="/apply/:id/:title" element={<JobApplication />} />
          </Route>

          <Route path="/jobs" element={<Careers />} />
          <Route
            path="/report/:id/:phoneNumber/:email/:title"
            element={<ReportAd />}
          />

          <Route path="/query/:term" element={<SearchPage />} />
          <Route path="/photo_upload" element={<PrivateRoute />}>
            <Route path="/photo_upload" element={<PhotoUpload />} />
          </Route>

          <Route path="/profile" element={<PrivateRoute />}>
            <Route path="/profile" element={<Profile />} />
          </Route>

          <Route path="/promote/:id" element={<PrivateRoute />}>
            <Route path="/promote/:id" element={<Promote />} />
          </Route>

          <Route path="/edit/:id" element={<EditAdInfo />}>
            <Route path="/edit/:id" element={<EditAdInfo />} />
          </Route>

          <Route path="/item/:id" element={<PostedAddDetails />} />
          <Route path="/products" element={<Products />} />
          <Route
            path="/mega/:categoryUrl/:subCategoryUrl"
            element={<MegaSearch />}
          />
          <Route path="/district/:name" element={<DistrictSearch />} />
          <Route
            path="/~btm/:category/:subcategory/:district"
            element={<BottomCategories />}
          />
          <Route path="/terms" element={<TermsPage />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/reset" element={<ResetPassword />} />
          <Route path="/site-map" element={<SiteMap />} />
          <Route path="/team" element={<Team />} />
          <Route
            path="/frequently_asked_questions"
            element={<FaqsPage />}
          ></Route>
          <Route path="*" element={<NotFound />} />
        </Routes>

        <Footer />
        <FixedBottom />
      </BrowserRouter>
    </>
  );
}

export default App;
