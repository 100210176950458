export const Uganda = [
  {
    district: "Arusha Region",
    divisions: [
      "Arumeru",
      "Arusha",
      "Karatu",
      "Longido",
      "Monduli",
      "Ngorongoro",
    ],
  },
  {
    district: "Dar es Salaam",
    divisions: ["Ilala", "Kinondoni", "Temeke"],
  },
  {
    district: "Mwanza Region",
    divisions: [
      "Ilemela",
      "Kwimba",
      "Magu",
      "Misungwi",
      "Nyamagana",
      "Sengerema",
      "Ukerewe",
    ],
  },
  {
    district: "Dodoma Region",
    divisions: ["Dodoma Rural"],
  },
  {
    district: "Geita Region",
    divisions: ["Bukombe", "Chato", "Geita Urban", "Mbogwe", "Nyang'hwale"],
  },

  {
    district: "Iringa Region",
    divisions: ["Iringa", "Iringa Municipal", "Kilolo", "Mafinga", "Mufindi"],
  },
  {
    district: "Kagera Region",
    divisions: [
      "Biharamulo",
      "Bukoba Rural",
      "Bukoba Urban",
      "Karagwe",
      "Kyerwa",
      "Missenyi",
      "Muleba",
      "Ngara",
    ],
  },
  {
    district: "Katavi Region",
    divisions: ["Mlele", "Mpanda", "Nsimbo"],
  },
  {
    district: "Kigoma Region",
    divisions: [
      "Buhigwe",
      "Kakonko",
      "Kasulu",
      "Kasulu Town",
      "Kibondo",
      "Kigoma Rural",
      "Kigoma Urban",
    ],
  },
  {
    district: "Kilimanjaro Region",
    divisions: [
      "Hai",
      "Moshi Rural",
      "Moshi Urban",
      "Mwanga",
      "Rombo",
      "Same",
      "Siha",
    ],
  },

  {
    district: "Lindi Region",
    divisions: ["Kilwa", "Lindi", "Liwale", "Nachingwea", "Ruangwa"],
  },
  {
    district: "Manyara Region",
    divisions: [
      "Babati Rural",
      "Babati Urban",
      "Hanang",
      "Kiteto",
      "Mbulu",
      "Simanjiro",
    ],
  },
  {
    district: "Mara Region",
    divisions: [
      "Bunda",
      "Butiama",
      "Musoma Rural",
      "Musoma Urban",
      "Rorya",
      "Serengeti",
      "Tarime",
    ],
  },
  {
    district: "Mbeya Region",
    divisions: [
      "Ileje",
      "Mbeya City",
      "Busokelo",
      "Chunya",
      "Isanga",
      "Kyela",
      "Mbarali",
      "Mbeya Rural",
      "Mbozi",
      "Momba",
      "Rungwe",
    ],
  },
  {
    district: "Morogoro Region",
    divisions: [
      "Kilombero",
      "Mikumi",
      "Gairo",
      "Kilosa",
      "Morogoro Rural",
      "Morogoro Urban",
      "Mvomero",
      "Ulanga",
    ],
  },
  {
    district: "Mtwara Region",
    divisions: ["Masasi", "Mtwara Urban", "Nanyumbu", "Newala", "Tandahimba"],
  },
  {
    district: " Njombe Region",
    divisions: [
      "Ludewa",
      "Makambako Urban",
      "Makete",
      "Njombe Rural",
      "Njombe Urban",
      "Wanging'ombe",
    ],
  },
  {
    district: "Pemba North Region",
    divisions: ["Micheweni", "Wete"],
  },
  {
    district: "Pemba South Region",
    divisions: ["Chake Chake", "Mkoani"],
  },
  {
    district: "Pwani Region",
    divisions: [
      "Kisarawe",
      "Bagamoyo",
      "Kibaha",
      "Mafia",
      "Mkuranga",
      "Rufiji",
    ],
  },

  {
    district: "Rukwa Region",
    divisions: ["Kalambo", "Lyamba lya Mfipa", "Nkasi", "Sumbawanga"],
  },
  {
    district: "Ruvuma Region",
    divisions: [" Mbinga", "Namtumbo", "Nyasa", "Songea", "Tunduru"],
  },
  {
    district: "Shinyanga Region",
    divisions: ["Kahama Rural", "Kahama Urban", "Kishapu", "Shinyanga Urban"],
  },
  {
    district: " Shinyanga Rural",
    divisions: [
      "Didia",
      "Ilola",
      "Imesela",
      "Iselamagazi",
      "Itwangi",
      "Lyabukande",
      "Mwakitolyo",
      "Mwamala",
      "Mwantini",
      "Pandagichiza",
      "Salawe",
      "Samuye",
      "Solwa",
      "Tinde",
      "Usanda",
      "Usule",
    ],
  },

  {
    district: "Simiyu Region",

    divisions: ["Bariadi", "Busega", "Itilima", "Maswa", "Meatu"],
  },
  {
    district: "Singida Region",
    divisions: [
      "Ikungi",
      "Iramba",
      "Manyoni",
      "Mkalama",
      "Singida Rural",
      "Singida Urban",
    ],
  },
  {
    district: "Tabora Region",

    divisions: [
      "Igunga",
      "Kaliua",
      "Nzega",
      "Sikonge",
      "Tabora Urban",
      "Urambo",
      "Uyui",
    ],
  },
  {
    district: "Tanga Region",
    divisions: [
      "Handeni Rural",
      "Handeni Urban",
      "Kilindi",
      "Korogwe Rural",
      "Korogwe Urban",
      "Lushoto",
      "Mkinga",
      "Muheza",
      "Pangani",
      "Tanga City",
    ],
  },
  {
    district: "Zanzibar",
    divisions: ["Mjini Magharibi", "Unguja North", "Unguja South"],
  },
];
