import React, { useContext, useState } from "react";
import logo from "../../lib/images/logo.webp";

import { useGlobalContext } from "../../lib/context/GlobalContext/GlobalContext";
import { Link } from "react-router-dom";
import { AuthContext } from "../../lib/context/AuthContext/auth";
import { RiArrowDropDownLine } from "react-icons/ri";
import { AiOutlineUserAdd } from "react-icons/ai";
import { signOut } from "firebase/auth";
import { auth } from "../../lib/Firebase/firebase";
import { RiMenu3Line } from "react-icons/ri";
import "./Navbar.css";

const Navbar = () => {
  const { user } = useContext(AuthContext);
  const [isOpen, setIsOpen] = useState(false);

  const { openSidebar, openSubmenu, closeSubmenu } = useGlobalContext();
  const displaySubmenu = (e) => {
    const page = e.target.textContent;
    const tempBtn = e.target.getBoundingClientRect();
    const center = (tempBtn.left + tempBtn.right) / 2;
    const bottom = tempBtn.bottom - 3;
    openSubmenu(page, { center, bottom });
  };
  const handleSubmenu = (e) => {
    if (!e.target.classList.contains("link-btn")) {
      closeSubmenu();
    }
  };
  return (
    <nav
      style={{
        width: "100%",
      }}
      className="nav z-depth-0 "
      onMouseOver={handleSubmenu}
    >
      <div className="nav-center">
        <div className="nav-header">
          <div
            style={{
              paddingTop: "18px",
            }}
            className="valign-wrapper"
          >
            <Link className="nav-logo" to="/">
              <span
                style={{
                  fontSize: "1.8rem",
                }}
              >
                bantumart
              </span>
              <img
                style={{
                  width: "20px",
                  objectFit: "contain",
                }}
                src={logo}
                alt=""
              />
            </Link>
          </div>

          <button
            style={{
              borderRadius: "5px",
              padding: "-10px 10px",
            }}
            className="btn toggle-btn red darken-4 z-depth-0"
            onClick={openSidebar}
          >
            <RiMenu3Line
              style={{
                marginTop: "8px",
              }}
              size={20}
              color="#fff"
            />
          </button>
        </div>
        <ul className="nav-links">
          <li>
            <button className="link-btn" onMouseOver={displaySubmenu}>
              Properties
            </button>
          </li>
          <li>
            <button className="link-btn" onMouseOver={displaySubmenu}>
              Vehicles
            </button>
          </li>
          <li>
            <button className="link-btn" onMouseOver={displaySubmenu}>
              Electronics
            </button>
          </li>

          <li>
            <button className="link-btn" onMouseOver={displaySubmenu}>
              Construction
            </button>
          </li>
          <li>
            <button className="link-btn" onMouseOver={displaySubmenu}>
              Agriculture
            </button>
          </li>
        </ul>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          {user ? (
            <>
              <button
                style={{
                  backgroundColor: "transparent",
                }}
                className="btn signin-btn z-depth-0"
              >
                <div
                  style={{
                    position: "relative",
                  }}
                >
                  <p
                    style={{
                      color: "#ff4820 ",
                      fontSize: "13px",
                    }}
                    onClick={() => {
                      setIsOpen(!isOpen);
                    }}
                  >
                    <span
                      style={{
                        display: "flex",
                      }}
                    >
                      <span
                        style={{
                          marginTop: 5,
                        }}
                      >
                        <AiOutlineUserAdd size={20} />
                      </span>
                      <span>user</span>
                      <span
                        style={{
                          marginTop: 5,
                        }}
                      >
                        <RiArrowDropDownLine size={25} />
                      </span>
                    </span>
                  </p>

                  <AccountDropdown isOpen={isOpen} setIsOpen={setIsOpen} />
                </div>
              </button>
            </>
          ) : (
            <>
              <button
                style={{
                  borderRadius: "20px",
                  backgroundColor: "transparent",
                  border: "none",
                  outline: "none",
                }}
                className=" signin-btn"
              >
                <Link to="/sign_up"> Sign up</Link>
              </button>
              <button
                style={{
                  borderRadius: "20px",
                  backgroundColor: "transparent",
                  border: "none",
                  outline: "none",
                  textTransform: "capitalize",
                }}
                className="btn signin-btn z-depth-0"
              >
                <Link to="/sign_in">Login</Link>
              </button>
            </>
          )}

          <button
            style={{
              borderRadius: "10px",
              fontWeight: "bold",
            }}
            className="btn signin-btn red hide-on-med-and-down"
          >
            <Link to="/post_ad">Post Add</Link>
          </button>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;

const AccountDropdown = ({ isOpen, setIsOpen }) => {
  return (
    <div
      className="red"
      style={{
        borderRadius: 10,
        position: "absolute",
        zIndex: 999,
        width: 120,
        height: 200,
        paddingTop: 20,
        top: 45,
        display: isOpen ? "block" : "none",
      }}
    >
      <p onClick={() => setIsOpen(!isOpen)}>
        {" "}
        <Link
          style={{
            color: "#fff",
            fontSize: 15,
          }}
          to="/profile"
        >
          Dashboard
        </Link>
      </p>
      <p onClick={() => setIsOpen(!isOpen)}>
        {" "}
        <Link
          style={{
            color: "#fff",
            fontSize: 15,
          }}
          to="/profile"
        >
          Your Ads
        </Link>
      </p>
      <p
        style={{
          fontSize: 15,
        }}
        className="white-text"
        onClick={async () => signOut(auth)}
      >
        log Out
      </p>
    </div>
  );
};
