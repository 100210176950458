import { initializeApp, getApp, getApps } from "firebase/app";
import { getAuth, FacebookAuthProvider, signInWithPopup } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyBm_s0tNqa7TuUfXewuzzog-hKqQO0_oWE",
  authDomain: "bantumart-tanzania.firebaseapp.com",
  projectId: "bantumart-tanzania",
  storageBucket: "bantumart-tanzania.appspot.com",
  messagingSenderId: "138504090939",
  appId: "1:138504090939:web:51748ee84ef39aa937f31e"
};

// Initialize Firebase
const app = !getApps().length ? initializeApp(firebaseConfig) : getApp();

const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

export {
  auth,
  db,
  storage,
  //GoogleAuthProvider,
  signInWithPopup,
  FacebookAuthProvider,
};
